@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700&family=Inter:wght@300;400;500;600&display=swap");

.ff_inter {
  font-family: "Inter", sans-serif;
}

.ff_abhaya {
  font-family: "Abhaya Libre", serif !important;
}

.circel_animate {
  animation: rounded infinite linear 7.5s;
}

html,
body {
  scroll-behavior: smooth;
}

@keyframes rounded {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.prev_arrow svg,
.next_arrow svg {
  width: 90%;
}

.prev_arrow:hover svg circle {
  fill: #cf1b1b !important;
}

.prev_arrow:hover svg path {
  fill: white !important;
}

.next_arrow:hover svg circle {
  fill: #cf1b1b !important;
}

.next_arrow:hover svg path {
  fill: white !important;
}

#remove_slider_dots .slick-next,
#remove_slider_dots .slick-prev {
  display: none !important;
}

/* footer css start ------------------------------ */
.footer_svg:hover rect {
  fill: #161616;
}

.footer_svg rect {
  transition: all 0.3s linear !important;
}

/*TOPBAR CSS HERE===============================================*/
.topbar_icon svg rect {
  transition: all 0.3s ease-in-out;
}

.topbar_icon svg path {
  transition: all 0.3s ease-in-out;
}

.topbar_icon:hover svg rect {
  fill: white;
  fill-opacity: 1;
}

.topbar_icon:hover svg path {
  fill: #cf1b1b;
}

/* BACK TO TOP */
.topscroll {
  border: 2px solid #fff;
  background-color: #1599bc;
  -webkit-animation: move 2s ease-in-out infinite;
  -webkit-animation: up-down 2.5s ease-in-out infinite;
  animation: up-down 2.5s ease-in-out infinite;
}

.topscroll:hover {
  -webkit-box-shadow: 0 0 17px #1599bc;
  box-shadow: 0 0 17px #1599bc;
}

@-webkit-keyframes up-down {
  50% {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}

@keyframes up-down {
  50% {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}

.moving_annimation_up_down {
  -webkit-animation: up-down_animation 2.5s ease-in-out infinite;
  animation: up-down_animation 2.5s ease-in-out infinite;
}

@-webkit-keyframes up-down_animation {
  50% {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}

@keyframes up-down_animation {
  50% {
    -webkit-transform: translateY(-7px);
    transform: translateY(-7px);
  }
}

/*PRELOADER CSS HERE--------------------------------*/
.loader-fullscreen {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 99999;
}

.overflow-hidden-while-loading {
  overflow: hidden !important;
  height: 100vh !important;
}

.loader-fullscreen img {
  max-width: 120px;
  width: 100%;
  -webkit-animation: load-animation 4s ease infinite;
  animation: load-animation 4s ease infinite;
}

@-webkit-keyframes load-animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes load-animation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.slider-image-parent {
  min-width: 393px;
  min-height: 558px;
}

.transform_rotate_red {
  transform: rotateY(180deg);
}

.rotate {
  animation: rotation 2.5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.customer-slider .slick-prev:before,
.customer-slider .slick-next:before {
  color: transparent;
  font-size: 0px !important;
}

.customer-slider .slick-prev,
.customer-slider .slick-next {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='19' viewBox='0 0 11 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.375 18.6C0.125 18.35 0 18.054 0 17.712C0 17.3707 0.125 17.075 0.375 16.825L7.7 9.5L0.35 2.15C0.116667 1.91667 0 1.625 0 1.275C0 0.925 0.125 0.625 0.375 0.375C0.625 0.125 0.921 0 1.263 0C1.60433 0 1.9 0.125 2.15 0.375L10.55 8.8C10.65 8.9 10.721 9.00833 10.763 9.125C10.8043 9.24167 10.825 9.36667 10.825 9.5C10.825 9.63333 10.8043 9.75833 10.763 9.875C10.721 9.99167 10.65 10.1 10.55 10.2L2.125 18.625C1.89167 18.8583 1.60433 18.975 1.263 18.975C0.921 18.975 0.625 18.85 0.375 18.6Z' fill='red'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border: 1px solid #cf1b1b;
  border-radius: 50%;
  min-width: 48px;
  min-height: 48px;
  transition: all 0.2s ease-in-out;
  z-index: 10;
}

.customer-slider .slick-prev {
  transform: rotate(180deg);
  left: -470px !important;
  top: 40% !important;
}

.customer-slider .slick-next {
  right: -75px !important;
}

.customer-slider .slick-prev:hover,
.customer-slider .slick-next:hover {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='19' viewBox='0 0 11 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.375 18.6C0.125 18.35 0 18.054 0 17.712C0 17.3707 0.125 17.075 0.375 16.825L7.7 9.5L0.35 2.15C0.116667 1.91667 0 1.625 0 1.275C0 0.925 0.125 0.625 0.375 0.375C0.625 0.125 0.921 0 1.263 0C1.60433 0 1.9 0.125 2.15 0.375L10.55 8.8C10.65 8.9 10.721 9.00833 10.763 9.125C10.8043 9.24167 10.825 9.36667 10.825 9.5C10.825 9.63333 10.8043 9.75833 10.763 9.875C10.721 9.99167 10.65 10.1 10.55 10.2L2.125 18.625C1.89167 18.8583 1.60433 18.975 1.263 18.975C0.921 18.975 0.625 18.85 0.375 18.6Z' fill='white'/%3E%3C/svg%3E%0A") !important;
  background-color: #cf1b1b !important;
}

.customer-slider .slick-dots {
  bottom: -55px !important;
  transform: translateX(-220px);
}

.customer-slider .slick-dots li {
  width: 16px;
  height: 16px;
}

.customer-slider .slick-dots li button:before {
  font-size: 16px !important;
  color: #4cabc7 !important;
}

.triangle-parent {
  transform: translateY(130px);
}

.text_overflow_dots01 {
  max-height: 308px;
  min-height: 308px;
  -webkit-line-clamp: 6;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.text_overflow_dots02 {
  max-height: 138px;
  min-height: 138px;
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

/* width */
div ::-webkit-scrollbar {
  width: 4px;
}
/* Track */
div ::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* Handle */
div ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}
/* Handle on hover */
div ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7);
}

@media (min-width: 1536px) {
  .customer-slider .slick-prev {
    left: -540px !important;
  }
}

@media (max-width: 1299.98px) {
  .customer-slider .slick-next {
    right: -60px !important;
  }

  .customer-slider .slick-prev {
    left: -460px !important;
  }
}

@media (max-width: 1279.98px) {
  .triangle-parent {
    transform: translateY(70px);
  }

  .customer-slider .slick-prev {
    left: -70px !important;
    top: 110% !important;
  }

  .customer-slider .slick-next {
    right: 520px !important;
    top: 120% !important;
  }
}

@media (max-width: 1023.98px) {
  .string_icon svg {
    max-width: 76px;
    max-height: 76px;
  }

  .customer-slider .slick-dots {
    bottom: -35px !important;
    transform: translateX(0px) !important;
  }

  .triangle-parent {
    transform: translateY(30px);
  }

  .text_overflow_dots01 {
    /* max-height: 404px; */
    /* min-height: 404px; */
    /* -webkit-line-clamp: 11; */

    max-height: 294px;
    min-height: 294px;
    -webkit-line-clamp: 7;
  }

  .text_overflow_dots02 {
    max-height: 128px;
    min-height: 128px;
  }
}

@media (max-width: 767.98px) {
  .slick-dots li button:before {
    font-size: 15px !important;
  }

  .customer-slider .slick-dots li {
    width: 12px;
    height: 12px;
  }

  .customer-slider .slick-dots li button:before {
    font-size: 12px !important;
  }

  .string_icon svg {
    max-width: 66px;
    max-height: 66px;
  }

  .clients_news_01 .slick-dots li.slick-active button:before {
    color: #cf1b1b !important;
  }

  .text_overflow_dots01 {
    max-height: 370px;
    min-height: 370px;
    -webkit-line-clamp: 10;
  }
}

@media (max-width: 639.98px) {
  .text_overflow_dots01 {
    max-height: 270px;
    min-height: 270px;
    -webkit-line-clamp: 8;
  }
  .text_overflow_dots02 {
    max-height: 100px;
    min-height: 100px;
    -webkit-line-clamp: 4;
  }
}

@media (min-width: 500px) and (max-width: 639.98px) {
  .customer_slider_img_01 {
    max-width: 50% !important;
    min-height: 450px !important;
    max-height: 450px !important;
    margin: auto !important;
  }
}

@media (min-width: 768px) {
  .hero_section_height {
    min-height: calc(100vh - 168px) !important;
  }
}

@media (max-width: 409.98px) {
  .mt_xs_16 {
    margin-top: 16px !important;
  }

  .slider-image-parent {
    min-width: 100%;
    min-height: 411px;
    height: 100%;
  }
}
